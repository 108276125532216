<template>
  <div class="timePicker">
    <div class="calendarone">
      <!-- <div class="title">时间：</div> -->
      <el-radio-group v-model="radio" size="small">
        <el-radio-button label="年"></el-radio-button>
        <el-radio-button label="月"></el-radio-button>
        <el-radio-button label="日"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="calendar">
      <div v-if="radio === '年'" class="el_calendar_year">
        <el-date-picker
          v-model="start_stats_year"
          type="year"
          :placeholder="startPlaceholder"
          :format="format"
          :value-format="formatValue"
          size="small"
          @change="changeYearData"
          :clearable="false"
        ></el-date-picker>
        ～
        <el-date-picker
          v-model="end_stats_year"
          type="year"
          :placeholder="endPlaceholder"
          :format="format"
          :value-format="formatValue"
          size="small"
          @change="changeYearData"
          :clearable="false"
        ></el-date-picker>
      </div>
      <el-date-picker
        v-else
        ref="datedate"
        size="small"
        v-model="dateMonthValue"
        :format="format"
        :value-format="formatValue"
        :type="type"
        :start-placeholder="startPlaceholder"
        :end-placeholder="endPlaceholder"
        class="Calendar"
        :clearable="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    radioParent: {
      type: String,
      default: '月',
    },
  },
  data() {
    return {
      radio: '', //时间节点
      start_stats_year: '', //开始年份
      end_stats_year: '', //结束年份
      dateMonthValue: '', //月份和日期选择
      yyyy: '',
      MM: '',
      dd: '',
    }
  },
  created() {
    const nowdate = new Date()
    this.yyyy =
      this.start_stats_year =
      this.end_stats_year =
        nowdate.getFullYear() + ''
    let MM = nowdate.getMonth() + 1,
      dd = nowdate.getDate()
    this.MM = MM <= 9 ? '0' + MM : MM
    this.dd = dd <= 9 ? '0' + dd : dd
    this.radio = this.radioParent
  },
  computed: {
    type() {
      const arr = {
        年: 'year',
        月: 'monthrange',
        日: 'daterange',
      }
      return arr[this.radio]
    },

    startPlaceholder() {
      const arr = {
        年: '开始年份',
        月: '开始月份',
        日: '开始日期',
      }
      return arr[this.radio]
    },
    endPlaceholder() {
      const arr = {
        年: '结束年份',
        月: '结束月份',
        日: '结束日期',
      }
      return arr[this.radio]
    },
    format() {
      const arr = {
        年: 'yyyy',
        月: 'yyyy/MM',
        日: 'yyyy/MM/dd',
      }
      return arr[this.radio]
    },
    formatValue() {
      const arr = {
        年: 'yyyy',
        月: 'yyyyMM',
        日: 'yyyyMMdd',
      }
      return arr[this.radio]
    },
  },
  watch: {
    type(val) {
      const yyyy = this.yyyy,
        MM = this.MM,
        dd = this.dd
      switch (val) {
        case 'year':
          this.start_stats_year = yyyy
          this.end_stats_year = yyyy

          break
        case 'monthrange':
          this.dateMonthValue = [yyyy + MM, yyyy + MM]
          break
        case 'daterange':
          this.dateMonthValue = [yyyy + MM + '01', yyyy + MM + dd]
          break
        default:
          break
      }
    },
    radio(value) {
      let timeData = {
        name: 'radio',
        value,
      }
      this.$emit('setTime', timeData)
    },
    start_stats_year(value) {
      let timeData = {
        name: 'start_stats_year',
        value,
      }
      this.$emit('setTime', timeData)
    },
    end_stats_year(value) {
      let timeData = {
        name: 'end_stats_year',
        value,
      }
      this.$emit('setTime', timeData)
    },
    dateMonthValue(value) {
      let timeData = {
        name: 'dateMonthValue',
        value,
      }
      this.$emit('setTime', timeData)
    },
  },
  methods: {
    // 年份联动选择处理,开始年份>结束年份
    changeYearData() {
      if (
        this.end_stats_year != '' &&
        this.start_stats_year > this.end_stats_year
      ) {
        this.end_stats_year = '' //结束年份
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.timePicker {
  display: flex;
  .calendarone {
    display: flex;
    align-items: center;
  }
  .calendar {
    margin-left: px(5);
    display: flex;
    align-items: center;
  }
}
</style>