<template>
  <div class="TJDetailTitle" :class="titleData==='仪器原始信息' ? 'instrument_title' : ''">
    {{ titleData }}
    <span v-if="titleData==='仪器原始信息'" style="float:right">
      <span  @click="handleStatus()" class="edit_btn">编辑</span>
    </span>
  </div>
</template>
<script>
export default {
  props: ['titleData'],
  data() {
    return {
    }
  },
  methods: {
    handleStatus(){
      this.$emit('changeDialog',true)
    },
  }
}
</script>
<style lang="scss" scoped>
.TJDetailTitle {
  height: 2.385417vw;
  background-color: transparent;
  display: flex;
  margin-bottom: px(5);
  font-weight: bold;
  color: #111111;
}
.instrument_title{
  justify-content: space-between;
}
::v-deep .el-button--primary.is-plain{
  background-color: #fff;
  border-color: #3a8ee6;
}
::v-deep .el-button--primary.is-plain:hover{
  color: #3a8ee6;
}
.edit_btn{
  font-size: px(18);
  padding: px(10) px(40);
  border:1px solid #4095E5;
  background: #fff;
  border-radius: 3px;
  color: #4095E5;
  font-weight: 400;
  cursor: pointer;
}
</style>