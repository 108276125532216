<template>
  <el-select
      v-model="province_id"
      placeholder="请选择省份"
      size="small"
      filterable
      clearable
      @change='changeProvince'

    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
</template>
<script>
export default {
  name: 'provinceSelect',
  data() {
    return {
      province_id: '', //省份标识
      options: JSON.parse(localStorage.getItem('province')), //地区下拉框
    }
  },
  methods:{
    changeProvince(){
      this.$emit('selectedProvince',this.province_id)
    }
  }
}
</script>