<template>
  <div class="indentBox">
    <div class="indentone" v-if="nowList.length!=0">
      <div >
        <p v-for="(item,index) in nowList" :key="index">
          <el-divider v-if="index!=0" direction="vertical" ></el-divider>
          <span class="title" :class="item.id === nowID?'nowTitle':''" @click="goPage(item)">
            {{ item.title  }}
          </span>
        </p>
      </div>
      <p class="NumCla">{{ totalText }}</p>
    </div>

    <div class="below">
      <ul class="left">
        <slot></slot>
      </ul>
      <p class="right">
<!--        <el-button  type="primary" size="small"  @click="getList" v-if="isInputStore">+新增入库</el-button>-->
        <el-button type="primary" size="small" @click="searchBtn" :disabled="searhLoading" :loading="searhLoading"><i style="margin-right: 4px" class="el-icon-search"/>搜&nbsp;索</el-button>
        <el-button  size="small" v-if="isAssetMgt || isAssetCheck" @click="clearSearch" :disabled="searhLoading" ><i style="margin-right: 4px" class="el-icon-refresh" />重&nbsp;置</el-button>
        <el-button
            type="success"
            size="small"
            @click="exportFun"
            :disabled="exportLoading" :loading="exportLoading"
            v-if="isExport"
        >
          导出
        </el-button></p>
      <p class="right-end">
        <el-dropdown v-if="isAssetMgt && (assetMGtObj.assetExport || [110, 103, 109].includes(userRoleId))"  size="small" style="margin-left: 10px" trigger="click"  @command="handleDownload">
          <el-button type="primary" class="export-btn" size="small">
            导&nbsp;出<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu v-slot="dropdown" >
            <el-dropdown-item  v-for="item in fileTypeList" :command="item.value" :key="item.value">{{ item.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button v-if="isAssetCheck"   type="primary" size="small" icon="el-icon-plus" @click="addCheck">新增盘点</el-button>
        <el-button v-if="isAssetCheck && (assetMGtObj.checkExport || [110, 103, 109].includes(userRoleId))"   type="primary" size="small" class="export-btn"  @click="exportCheck">导出<i class="el-icon-top-right el-icon--right"></i></el-button>


      </p>

    </div>
  </div>
</template>

<script>
import {addMenu, getAssetMgtBtn, getReport} from '@/router/menuMain.js'
export default {
  props: {
    totalText: {
      type: String,
      default: '',
    },
    titleData: {
      type: Object
    },
    isExport: {
      type: Boolean,
      default: false
    },
    searhLoading: {
      type: Boolean,
      default: false
    },
    exportLoading: {
      type: Boolean,
      default: false
    },
    isAssetMgt: {
      type: Boolean,
      default: false
    },
    isAssetCheck:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userRoleId: JSON.parse(localStorage.getItem('userInfo')).org_post,
      assetMGtObj:{
        assetExport: false, //资产导出
        checkExport: false, //盘点导出
      },
      fileTypeList:[
        {label:'PDF', value:2},
        {label:'CSV', value:1},
        {label:'EXCEL', value:3}
      ],
      statisticsList: [
        {
          permission_flag: 'Dosage',
          list: [
            {
              id: 1,
              title: '省份',
              path: '/Dosage',
            },
            {
              id: 2,
              title: '医院',
              path: '/DosageHospital',
            },
            {
              id: 3,
              title: '仪器',
              path: '/Device',
            },
          ]
        },
        {
          permission_flag: 'Sales',
          list: [
            {
              id: 1,
              title: '省份',
              path: '/Sales',
            },
            {
              id: 2,
              title: '经销商',
              path: '/Agency',
            },
          
            {
              id: 4,
              title: '日成交量',
              path: '/Turnover',
            },
          ]
        },
        {
          permission_flag: 'Maintain',
          list: [
            {
              id: 1,
              title: '省份',
              path: '/Maintain',
            },
            {
              id: 2,
              title: '医院',
              path: '/GoAgency',
            },
            {
              id: 3,
              title: '仪器',
              path: '/GoVolume',
            },
          ]
        },
        {
          permission_flag: 'SparePart',
          list: []
        },
        {
          permission_flag: 'Samples',
          list: []
        }
      ],
      nowID: null,
      nowList: [
      ],
      Report: false
    }
  },
  created() {
    let menuList = JSON.parse(localStorage.getItem('menuList'))
    addMenu(menuList);
    this.assetMGtObj = getAssetMgtBtn()// 缓存里取按钮权限
    this.Report = getReport();
    if(this.Report){
      this.statisticsList[1].list = [ {
              id: 1,
              title: '省份',
              path: '/Sales',
            },
            {
              id: 2,
              title: '经销商',
              path: '/Agency',
            },
            {
              id: 3,
              title: '报告',
              path: '/Volume',
            },
            {
              id: 4,
              title: '日成交量',
              path: '/Turnover',
            },]
    }
    this.nowList = this.statisticsList.find(e => e.permission_flag == this.titleData.permission_flag).list
    this.nowID = this.titleData.nowID
  },
  methods: {
    getList(){
      this.$emit('getList')
    },
    handleDownload(command){
      this.$emit('handleDownload',command)
    },
    searchBtn() {
      this.$emit('searchBtn')
    },
    clearSearch() {
      this.$emit('clearSearch')
    },
    exportFun() {
      this.$emit('exportFun')
    },
    exportCheck(){
      this.$emit('exportCheck')
    },
    goPage(item) {
      if(item.id != this.nowID){
        this.$router.push({ path: item.path})
      }
    },
    addCheck(){
      this.$emit('addCheck')
    }
  }
}
</script>

<style lang="scss" scoped>
.indentBox {
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 5px;
  margin-bottom: px(20);
  padding:  px(15);
}
.indentone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d7e0;
  padding: px(15) 0; 
  &>div {
    display: flex;
    justify-content: flex-start;
    .title {
      font-weight: bold;
      color: #666666;
      margin:0 px(10);
      cursor: pointer;
    }
    .nowTitle {
      color: #111111;
    }
  }
  .NumCla {
    font-weight: bold;
    color: #111111;
  }
}
.below {
  display: block;
  justify-content: space-between;
  padding: px(10) 0;
  .left {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &>li {
      display: inline-block;
      margin: px(10) px(10) 0 0;
    }
  }
  .right {
    width: 50%;
    display: inline-block;
    margin-top: px(20);
  }
  .right-end{
    width: 50%;
    display: inline-block;
    text-align: right;
    margin-top: px(20);
    vertical-align: top;
  }
}
.export-btn{
  background: #99b73f;
}
</style>